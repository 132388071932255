@mixin pixelated-canvas {
  -ms-interpolation-mode: bicubic;
  image-rendering: -moz-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  image-rendering: pixelated;
}

@mixin flex-positioner {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  pointer-events: none;

  * {
    pointer-events: all;
  }

}

