@use "./mixins.scss";

.pixel-colour-row {

  position: relative;
  padding: 6px 10px;
  margin: 5px;
  border: solid 1px;
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;

  &:hover {
    background: #1c2840;
    cursor: pointer;
  }

}

.pixel-preview {

  @include mixins.pixelated-canvas;

  height: 1.8em;
  width: 1.8em;
  box-shadow: 0px 0px 5px 0px #0008;

  @media only screen and (max-width: 350px) {
    display: none;
  }

}

.pixel-colour {
  width: 15px;
  height: 15px;
}

.pixel-colour-balance {
  margin-left: auto;
  font-family: monospace;
}

