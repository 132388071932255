/* hebrew */
@font-face {
  font-family: "Heebo";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(fonts/heebo/v21/NGSpv5_NC0k9P_v6ZUCbLRAHxK1EiSysd0mg7UiCXB5WkK8.woff)
    format("woff");
  unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin */
@font-face {
  font-family: "Heebo";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(fonts/heebo/v21/NGSpv5_NC0k9P_v6ZUCbLRAHxK1EiSysdUmg7UiCXB5W.woff)
    format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
