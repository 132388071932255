.react-transform-component {
  display: flex;
  flex-wrap: wrap;
  height: -moz-fit-content;
  height: fit-content;
  margin: 0;
  padding: 0;
  transform-origin: 0% 0%;
  width: -moz-fit-content;
  width: fit-content;

  img {
    pointer-events: none;
  }
}

.react-transform-wrapper {
  height: -moz-fit-content;
  height: fit-content;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: relative;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  width: -moz-fit-content;
  width: fit-content;
}
