@use "./mixins.scss";

.palette-container {
  @include mixins.flex-positioner;
  justify-content: center;
  align-items: flex-end;
}

.palette {
  position: absolute;
  display: grid;
  grid-template-columns: repeat(8, auto);
  border: 8px solid rgba(17, 24, 39, 0.6);
  margin-bottom: 25px;
}

.palette-colour {
  width: 35px;
  height: 35px;
  cursor: pointer;
  &.selected {
    transform: scale(1.15);
    border: 1px solid white;
    z-index: 100;
    margin: -1px;
  }
}

