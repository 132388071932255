.modal {
  position: absolute;
  width: 650px;
  max-height: calc(100% - 120px);
  left: calc(50% - 345px);
  margin: 40px 0;
  background: #111827;
  color: white;
  padding: 20px;
  box-shadow: 0px 0px 10px 5px #0008;
  border-radius: 3px;

  // Flex needed to have overflow work correctly
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 700px) {
    width: auto;
    margin: 0;
    left: 0;
    right: 0;
    border-radius: 0;
  }
}

.modal-background {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #0005;
  z-index: 200;
  backdrop-filter: blur(2px);
}

.modal-header {
  margin-bottom: 10px;
  font-size: 2em;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 10px;
  gap: 15px;
}

.modal-footer {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
}

.modal-content {
  overflow-y: auto;
  padding: 10px;
}

.closeBtn {
  margin-left: auto;
  cursor: pointer;
  align-self: start;
}

.modal-error {
  text-align: center;
}

.modal-loading {
  text-align: center;
  font-size: 1.5em;
}

.modal-button-container {
  margin: 30px 0 10px 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 30px;

  button {
    font-size: 1em;
    border-radius: 10px;
    text-transform: uppercase;
    padding: 5px 30px;
    border: 2px solid white;
    font-weight: bold;
  }

  .primary {
    color: #111827;
    background: white;
    &:hover {
      background: #a2abc0;
    }
  }

  .secondary {
    color: white;
    background: #111827;
    &:hover {
      background: #1c2840;
    }
  }
}
