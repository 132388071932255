@import url("google-fonts-1663605985735.css");

body {
  margin: 0;
  font-family: "Heebo", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.card {
  box-shadow: 0px 0px 15px 5px #0007;
}

button {
  color: white;
  &:hover {
    background: #ccd;
    cursor: pointer;
  }
}

a {
  cursor: pointer;
  color: white;
  text-decoration: underline;
  text-underline-offset: 4px;
}
