@use "./mixins.scss";

$button-color: rgba(17, 24, 39, 0.6);

.load-screen {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;

  background: #223;
  width: 100%;
  height: 100%;
  font-size: 3em;
  color: white;
}

.canvas-error {
  position: absolute;
  margin: 90px 0 0 25px;
  padding: 10px 20px 22px 20px;
  border-radius: 12px;
  box-shadow: 0px 0px 7px 1px #000a;

  background: #111827;
  color: white;
}

.canvas-error::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 12px;
  background: #a10035;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.coordinates-container {
  justify-content: right;
}

.coordinates {
  position: absolute;
  top: 0;
  margin: 25px;
  padding: 15px 16px;
  border-radius: 200px;
  background: $button-color;
  color: white;
  pointer-events: none;
  font-family: monospace;
}

.options-buttons {
  top: 0;
  right: 0;
  display: grid;
  grid-template-columns: repeat(1, auto);
  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

.options-buttons,
.zoom-buttons {
  position: absolute;
  margin: 15px;

  button {

    margin: 10px;
    font-size: 1.5em;
    min-width: 50px;
    min-height: 50px;
    border-radius: 50%;
    background-color: $button-color;
    border: none;

    &.active {
      background: radial-gradient(rgba(0, 140, 200, 0.9) 0%, $button-color);
      border: solid 2px rgb(0, 180, 255);
    }

    &:hover {
      cursor: pointer;
      background: rgb(17, 24, 39);
    }

  }
}
