.camera-wrapper {
  position: absolute !important;
  width: 100% !important;
  height: 100% !important;
}

.clickable {
  cursor: pointer
}

.zoom-buttons {
  bottom: 0;

  img {
    // A trial and error adjustment to make the zoom images set nicely in button
    // This doesn't appear consistently across browsers but should be good enough
    // for now
    padding-top: 0.3em;
  }

  @media only screen and (max-width: 700px) {
    display: none;
  }

}

